import React from "react"
import PropTypes from "prop-types"
import Header from "../components/header"
import SEO from "../components/seo"
import "../assets/scss/style.scss"
import styled from "@emotion/styled"

const LayoutDiv = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  min-height: 100vh;
`

const Layout = ({ children, title }) => {
  return (
    <>
      <SEO title={title}/>
      <LayoutDiv>
        <Header/>
        <main>{children}</main>
      </LayoutDiv>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
