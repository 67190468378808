import React from "react"
import styled from '@emotion/styled'
import Logo from "../../static/images/logo-compact.svg"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const HeaderContainer = styled.div`
  display: flex;
  border-bottom: 1px solid white;
  padding: 15px;
  
  svg {
    max-width: 150px;
  }
  
  @media (max-width: 640px) {
  }
    
  @media (min-width: 641px) {
    padding: 30px 20px;
    
    svg {
      max-width: 200px;
    }
  }
`

const HeaderLink = styled.div`
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-family: 'gt_america_black', Tahoma, sans-serif;
  //font-size: ${props => (props.fontSize || '1em')};
  flex-grow: ${props => (props.center ? '1' : 'initial')};
  font-size: 1em;
  transition: all .5s ease-in;
    
  span {
    padding-top: 4px;
    display: block;
  }
  
  &:hover {
    color: #008100;
    text-decoration: line-through;
  }
  
  @media (min-width: 641px) {
    font-size: 1.6em;
  }
  
  ${props => (props.hideonsmall ? `
    @media (max-width: 640px) {
      display: none;
    }
  ` :  '')}
`
// Styled Component with Gatsby Link
const Link = HeaderLink.withComponent(AniLink)

const Header = ({ siteTitle }) => {
  return (
    <header>
      <HeaderContainer>

        <Link
        to='/agenda'
        hideonsmall='true'
        swipe
        direction="right"
        top="entry">
          <span>Agenda</span>
        </Link>

        <Link
        to='/home'
        center='true'
        swipe
        direction="down"
        top="exit">
          <Logo/>
        </Link>

        <Link
        to='/about'
        hideonsmall='true'
        swipe
        direction="left"
        top="entry">
          <span>About</span>
        </Link>

      </HeaderContainer>
    </header>
  )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }
//
// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header
