import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image"

function generateRandomNumber() {
  let min = 0,
      max = 0.75;
  return Math.random() * (max - min) + min;
}

const BackgroundImages = ({ images }) => {

  // const browserWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  // const browserHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  const ImageContainer = styled.section`
    position: absolute;
    top: 0;
    z-index: -1;
    height: 100vh;
    width: 100vw;
    //overflow: hidden;
  `

  const Image = styled.div`
    position: absolute !important;
    width: 50%;
    top: ${props => `${props.top * 100}%`};
    left: ${props => `${props.left * 100}%`};
    
    @media (min-width: 1200px) {
      width: 40%;
    }
    
    // animate images
    @keyframes fadein {from {opacity: 0;} to {opacity: 1;}}
    &:nth-of-type(1) {animation: fadein 2s;}
    &:nth-of-type(2) {animation: fadein 2.5s;}    
    &:nth-of-type(3) {animation: fadein 3s;}    
    &:nth-of-type(4) { animation: fadein 3.5s;}
    &:nth-of-type(5) { animation: fadein 4s;}
    &:nth-of-type(6) { animation: fadein 4.5s;}
  `

  // Styled Component with Gatsby Link
  const BackgroundImage = Image.withComponent(Img)

  return (
    <ImageContainer>
      {images.map((page, key) => {
        return (
          <BackgroundImage fluid={page.fluid} key={key} left={generateRandomNumber()} top={generateRandomNumber()}/>
        )
      })}
    </ImageContainer>
  )
}

export default BackgroundImages

BackgroundImages.defaultProps = {
  images: []
}
